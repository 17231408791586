<template>
    <div class="page">
        <div class="banner">
            <img :src="require('@/assets/home/wj_banner_jskh.png')" alt="">
        </div>

        <div class="title">请选择考核类型</div>

        <div class="box">
            <div class="item since" @click="changeCheck(0)">
                <img :src="require('@/assets/home/wj_tb_wj.png')" alt="">
                <div class="info">
                    <div class="tit">自评</div>
                </div>
            </div>
            <div class="item check" @click="changeCheck(1)">
                <img :src="require('@/assets/home/wj_tb_sp.png')" alt="">
                <img class="mySp" :src="require('@/assets/home/wj_bq_wdkh.png')" alt="">
                <div class="info">
                    <div class="tit">考核评</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        changeCheck(type) {
            this.$router.push({ path: '/check/index', query: { type: type } })
        }
    },
}
</script>
<style lang="less" scoped>
.page {
    background: #f4f8fb;
}

.banner {
    img {
        width: 100%;
    }
}

.title {
    color: #98a3ad;
    text-align: center;
    margin: 10px 0;
}

.box {
    .item {
        width: 90%;
        margin: 0 auto 10px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 20px;

        img {
            width: 60px;
            height: 60px;
        }

        .info {
            margin-left: 10px;

            .tit {
                color: #222222;
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 10px;
            }

            .bottom {
                color: #98a3ad;
                font-size: 14px;

                .child1 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    >div {
                        margin: 0 4px;
                    }

                    .not {
                        color: #ff2c2c;
                        border: 1px solid #ffa0a0;
                        border-radius: 50px;
                        padding: 2px 10px;
                    }
                }

                .child2 {
                    span {
                        color: #0195ff;
                    }
                }
            }
        }
    }

    .since {
        position: relative;

        .not {
            position: absolute;
            top: 0;
            right: 0;
            width: 54px;
            height: 20px;
        }
    }

    .check {
        position: relative;

        .mySp {
            position: absolute;
            top: 0;
            left: 0;
            width: 64px;
            height: 23px;
        }
    }
}
</style>